<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>

      <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container v-if="!loadPage">
      <v-row>
        <v-col class="text-left" :cols="$vuetify.breakpoint.smAndUp ? 12 : ''">
          <div class="page-content" v-if="page" v-html="page.body"></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// import { bus } from "../mixins/bus.js";
import { mapGetters } from "vuex";
export default {
  components: {},
  data: () => ({
    pageSlug: "",
  }),
  methods: {
    getPage() {
      let app = this;
      this.$store
        .dispatch("getPage", {
          page_slug: app.pageSlug,
        })
        .then(function (responce) {
          if (responce.data.success == 1) {
            app.closeHello();
          }
        });

      //event.preventDefault();
      // this.loading = true;
      // var app = this;
      // axios
      //   .get(
      //     "/get-page/" + app.postSlug
      //   )
      //   //.get("/get-books/boevik?post=2")
      //   .then(function(resp) {
      //     app.loading = false;
      //     if (resp.data.success == 1) {
      //       app.post = resp.data.post;
      //       console.log(app.post);
      //       app.postTitle = app.post.title;
      //     }
      //     app.loadPage = true;
      //   })
      //   .catch(function(error) {
      //     app.loading = false;
      //     app.loadPage = true;
      //   });
    },
    closeHello() {
      this.$store.commit("SET_CLOSE_HELLO");
    },
  },
  computed: {
    ...mapGetters(["currentUser", "loadPage", "page"]),
    pageTitle() {
      if (this.page) {
        return this.page.title;
      }
      return "";
    },
  },
  mounted() {
    this.pageSlug = this.$route.params.slug ? this.$route.params.slug : "";
    this.getPage();
  },
};
</script>
